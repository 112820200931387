import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoadingPage() {
  return (
    <div className="d-flex justify-content-center min-vh-100 flex-column">
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
        <p className="mt-4 h3">Processing...</p>
        <p className="h3">Please wait.</p>
      </div>
    </div>
  );
}

LoadingPage.propTypes = {};

export default LoadingPage;
